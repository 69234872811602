import React, { useState, useEffect } from 'react';
import ComponentGrid from './ComponentGrid';

function Header() {
  const regionMaps = {
    'NA': 'US / Canada',
    'EU': 'Europe'
  };
  const componentTypeMaps = {
    'CPU': 'Processors',
    'GPU': 'Graphics Cards',
    'MPC': 'Mini PCs',
    'NAS': 'NAS'
  };

  const regions = Object.keys(regionMaps);
  const componentTypes = Object.keys(componentTypeMaps);

  const [selectedRegion, setSelectedRegion] = useState('EU');
  const [selectedComponentType, setSelectedComponentType] = useState('GPU');
  const [searchResults, setSearchResults] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  // Add useEffect for initial search
  useEffect(() => {
    performSearch(selectedRegion, selectedComponentType);
  }, []); // Empty dependency array means this runs once on mount

  const trackEvent = (category, action, label) => {
    if (process.env.NODE_ENV === 'production' && window.gtag) {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label
      });
    }
  };

  const handleSelectionChange = (type, value) => {
    switch (type) {
      case 'region':
        setSelectedRegion(value);
        trackEvent('Filter', 'region_change', value);
        setTimeout(() => performSearch(value, selectedComponentType), 0);
        break;
      case 'componentType':
        setSelectedComponentType(value);
        trackEvent('Filter', 'component_change', value);
        setTimeout(() => performSearch(selectedRegion, value), 0);
        break;
      default:
        break;
    }
  };

  const performSearch = (region, componentType) => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:55000';
    
    setIsLoading(true); // Set loading state
    
    fetch(`${baseUrl}/api/get-data`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        region: region,
        componentType: componentType
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(response => {
      const { data: components, created_at } = response;
      setSearchResults(components || []);
      setLastUpdated(created_at);
      setIsLoading(false); // Clear loading state
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setSearchResults([]);
      setIsLoading(false); // Clear loading state on error
    });
  };

  return (
    <>
      <div className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand me-auto d-flex align-items-center" href="#">
              <img 
                src="/logo512.png" 
                alt=""
                height="32"
                className="d-inline-block me-2"
              />
              PC Parts Finder
            </a>
            
            {/* Remove collapse button since we want buttons always visible */}
            
            {/* Change this div to be always visible */}
            <div className="navbar-collapse" id="navbarContent">
              <div className="ms-auto">
                <div className="row g-2">
                  <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-center justify-content-lg-end">
                      {regions.map((region) => (
                        <button
                          key={region}
                          className={`btn btn-sm ${selectedRegion === region ? 'btn-primary' : 'btn-outline-secondary'} me-1 mb-1`}
                          onClick={() => handleSelectionChange('region', region)}
                        >
                          {regionMaps[region]}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-center justify-content-lg-end">
                      {componentTypes.map((type) => (
                        <button
                          key={type}
                          className={`btn btn-sm ${selectedComponentType === type ? 'btn-primary' : 'btn-outline-secondary'} me-1 mb-1`}
                          onClick={() => handleSelectionChange('componentType', type)}
                        >
                          {componentTypeMaps[type]}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <ComponentGrid 
        components={searchResults} 
        lastUpdated={lastUpdated}
        isLoading={isLoading}
        region={selectedRegion}
      />
    </>
  );
}

export default Header;
