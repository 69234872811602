import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <footer className="text-center bg-light text-muted">
        <section>
          <div className="container py-4">
            <div className="mb-4">
              <img 
                src="/logo_wide.png" 
                alt="PC Parts Finder"
                height="48"
                className="d-inline-block"
              />
            </div>
            <p className="mb-4 px-4">
              Select a country and PC part to get started.
              Refine your search further with filtering and sorting.
            </p>
          </div>
        </section>
        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
          © 2025 Copyright:
          <a className="text-reset fw-bold ms-1" href="mailto:contact@pcparts.info">contact@pcparts.info</a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
