import React, { useState, useEffect } from 'react';
import './ComponentGrid.css';

function ComponentGrid({ components = [], lastUpdated, isLoading, region = 'EU' }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('default');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const [selectedCurrency, setSelectedCurrency] = useState('EUR');
  const [exchangeRates, setExchangeRates] = useState(null);

  const showCurrencySelector = region === 'EU';

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, sortBy]);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/EUR');
        const data = await response.json();
        setExchangeRates(data.rates);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
    // Refresh rates every hour
    const interval = setInterval(fetchExchangeRates, 3600000);
    return () => clearInterval(interval);
  }, []);

  const sortComponents = (components) => {
    const getPriceValue = (component) => {
      if (!component.prices || component.prices.length === 0) return Infinity;
      // Use the lowest price for sorting
      return Math.min(...component.prices
        .map(p => parseFloat(p.price_value || Infinity)));
    };

    switch (sortBy) {
      case 'price-asc':
        return [...components].sort((a, b) => getPriceValue(a) - getPriceValue(b));
      case 'price-desc':
        return [...components].sort((a, b) => getPriceValue(b) - getPriceValue(a));
      case 'name-asc':
        return [...components].sort((a, b) => a.title.localeCompare(b.title));
      case 'name-desc':
        return [...components].sort((a, b) => b.title.localeCompare(a.title));
      default:
        return components;
    }
  };

  const getRelativeTimeString = (timestamp) => {
    const now = new Date();
    const lastUpdate = new Date(timestamp);
    const diffInHours = Math.floor((now - lastUpdate) / (1000 * 60 * 60));
    
    if (diffInHours < 1) {
      const diffInMinutes = Math.floor((now - lastUpdate) / (1000 * 60));
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours === 1) {
      return '1 hour ago';
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      const diffInDays = Math.floor(diffInHours / 24);
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    }
  };

  const getVisiblePages = (currentPage, totalPages) => {
    const maxVisible = 10;
    let startPage = 1;
    let endPage = Math.min(totalPages, maxVisible);

    if (currentPage > maxVisible) {
      // If current page is beyond maxVisible, center it
      startPage = currentPage - Math.floor(maxVisible / 2);
      endPage = startPage + maxVisible - 1;

      // Adjust if we're near the end
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxVisible + 1);
      }
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  const convertPrice = (priceValue, fromCurrency, toCurrency) => {
    if (!exchangeRates || !priceValue) return priceValue;
    
    // Convert to EUR first (base currency)
    let inEUR = fromCurrency === 'EUR' 
      ? parseFloat(priceValue)
      : parseFloat(priceValue) / exchangeRates[fromCurrency];
      
    // Then convert to target currency
    return toCurrency === 'EUR'
      ? inEUR
      : inEUR * exchangeRates[toCurrency];
  };

  const filteredComponents = components
    .filter(component => component.title.toLowerCase().includes(searchTerm.toLowerCase()));
  
  const sortedComponents = sortComponents(filteredComponents);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentComponents = sortedComponents.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedComponents.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const PaginationNav = () => (
    <nav aria-label="Component pages" className="mt-4">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button 
            className="page-link" 
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            aria-label="First page"
          >
            &laquo;
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button 
            className="page-link" 
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            aria-label="Previous page"
          >
            &lt;
          </button>
        </li>
        
        {getVisiblePages(currentPage, totalPages).map(number => (
          <li 
            key={number} 
            className={`page-item ${currentPage === number ? 'active' : ''}`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </button>
          </li>
        ))}
        
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button 
            className="page-link" 
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            aria-label="Next page"
          >
            &gt;
          </button>
        </li>
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button 
            className="page-link" 
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            aria-label="Last page"
          >
            &raquo;
          </button>
        </li>
      </ul>
    </nav>
  );

  return (
    <div className="component-grid container mt-5">
      <div className="row mb-4">
        <div className="col-md-5">
          <input
            type="text"
            className="form-control"
            placeholder="Start typing to filter your results..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <select 
            className="form-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="default">Sort by...</option>
            <option value="price-asc">Price: Low to High</option>
            <option value="price-desc">Price: High to Low</option>
            <option value="name-asc">Name: A to Z</option>
            <option value="name-desc">Name: Z to A</option>
          </select>
        </div>
        {showCurrencySelector && (
          <div className="col-md-2">
            <div className="btn-group w-100">
              <button
                type="button"
                className={`btn ${selectedCurrency === 'EUR' ? 'btn-primary' : 'btn-outline-secondary'}`}
                onClick={() => setSelectedCurrency('EUR')}
              >
                €
              </button>
              <button
                type="button"
                className={`btn ${selectedCurrency === 'GBP' ? 'btn-primary' : 'btn-outline-secondary'}`}
                onClick={() => setSelectedCurrency('GBP')}
              >
                £
              </button>
            </div>
          </div>
        )}
        <div className={`col-md-${showCurrencySelector ? '3' : '5'} d-flex align-items-center justify-content-end`}>
          <span className="me-4">
            Total items: {components.length}
          </span>
          {lastUpdated && (
            <span className="text-muted">
              Last updated: {getRelativeTimeString(lastUpdated)}
            </span>
          )}
        </div>
      </div>
      
      {isLoading ? (
        <div className="text-center mt-5" style={{ minHeight: '48vh' }}>
          <div className="spinner-border text-primary mb-3" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <h3>Loading results...</h3>
          <p className="text-muted">
            Fetching the latest data.
          </p>
        </div>
      ) : sortedComponents.length > 0 ? (
        <>
          <h2 className="text-center mb-4">
            <small className="text-muted ms-2">
              {sortedComponents.length} items
            </small>
          </h2>

          {/* Top pagination */}
          {totalPages > 1 && <PaginationNav />}

          <div className="row row-cols-1 row-cols-md-3 g-3">
            {currentComponents.map(component => (
              <div key={component.id} className="col">
                <div className="card shadow-sm h-100">
                  <div className="image-container">
                    <img src={component.image} alt={component.title} className="card-img-top component-image" />
                  </div>
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title multiline-text">{component.title}</h5>
                    <div className="mt-3">
                      {component.prices.map((priceInfo, index) => {
                        const originalPrice = parseFloat(priceInfo.price_value);
                        const price = showCurrencySelector 
                          ? convertPrice(originalPrice, priceInfo.currency, selectedCurrency)
                          : originalPrice;
                        
                        const formattedPrice = new Intl.NumberFormat('en-GB', {
                          style: 'currency',
                          currency: showCurrencySelector ? selectedCurrency : priceInfo.currency,
                          currencyDisplay: region === 'EU' ? 'symbol' : 'narrowSymbol' // This will show just $ instead of US$
                        }).format(price);
                        
                        return (
                          <a 
                            key={`${priceInfo.source}-${index}`}
                            href={priceInfo.url} 
                            className="btn btn-sm btn-outline-secondary w-100 mb-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {formattedPrice} on {priceInfo.source}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Bottom pagination */}
          {totalPages > 1 && <PaginationNav />}
        </>
      ) : (
        <div className="text-center mt-5" style={{ minHeight: '48vh' }}>
          <h3>No components found</h3>
          <p className="text-muted">
            This feature might not be implemented yet. Please check back soon as we continue to expand our component coverage.
          </p>
        </div>
      )}
    </div>
  );
}

export default ComponentGrid;
