import React from 'react';
import Header from './Header';
import ComponentGrid from './ComponentGrid';
import Footer from './Footer';

function App() {
  return (
    <div>
      <Header />
      {/* <ComponentGrid /> */}
      <Footer />
    </div>
  );
}

export default App;
